import { useEffect, useContext, useState } from "react";
import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";
import GlobalContext from "GlobalContext";


export default function FinPrograms({ title , appDisclaimer , pageTitle, ExportChartFootnote, menuItemsStrings}) {
  useEffect(() => {
    document.title = `${pageTitle} - ${title}`;
  }, [pageTitle,title]);

  const { app } = useContext(GlobalContext);
  const [err, setError] = useState(null);
  const [FilterHeightInvestmentProjects, setFilterHeight] = useState(688);

  useEffect(() => {
    app.model.waitForOpen.promise.then(() => {
      setTimeout(async () => {
        app.model.enigmaModel.evaluate("$(=count({1}distinct [_col_id_investmentprojects]))").then(async (reply) => {
          const InvestmentProjectsNumberOfFilterRows = reply*27+40;
          console.log('InvestmentProjectsNumberOfFilterRows/Number of rows: ', InvestmentProjectsNumberOfFilterRows);
          setFilterHeight(InvestmentProjectsNumberOfFilterRows);
        }).catch(err => {
          if(!err) setError(err);
        });
      }, 500);
    });
  }, [app, err])

  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            <h6 className="page-disclaimer"> {appDisclaimer} </h6>
            {/* <QlikObject
              className="page-title"
              id="ANJWBAj"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="VXWetj"
              height="30px"
              showLoader={false}
            />
          </div> */}
        </div>
      </div>
          
      <div className="container-fluid">

        <div className="row justify-content-start">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">

              <div className="row justify-content-left">
                {/* KPIs Row */}

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of financial programs */}
                      <QlikCard
                        id="c75eea66-b068-47e0-8a13-fe5bc48c1e89"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: No of projects selected */}
                      <QlikCard
                        id="9a17902d-86de-4827-ad9a-c8f3e43db062"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Initiators count */}
                      <QlikCard
                        id="1d1662f3-c52b-4348-8ef3-bd53e8173a4e"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                      {/* KPI: Commited funding */}
                      <QlikCard
                        id="3b951e2e-79a7-4ee3-a94c-ec64560f16a8"
                        height="90px"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                      />
                    </div>

              </div>


              <div className="row justify-content-left">
                {/* Charts Row */}

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* barchart column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* barchart chart */}
                      <QlikCard
                        id="BFwnqp"
                        height="min(calc(78vh - 263px), calc(320px + 38px))"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>

                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* map column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* map */}
                      <QlikCard
                        id="LQEjw"
                        height="min(calc(78vh - 263px), calc(320px + 38px))"
                        showHeader={true}
                        showMaximize={false}
                        showExport={false}
                        showPdfExport={true}
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  {/* barchart column */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* barchart */}
                      <QlikCard
                        id="KVUyCr"
                        height="min(calc(78vh - 263px), calc(320px + 38px))"
                        showHeader={true}
                        showMaximize={false}
                        showExport={true}
                        showPdfExport
                        showImageExport={true}
                        exportImageWidth={1280}
                        exportImageHeight={720}
                        onBeforeExportHandler={async ({ vis, exportType }) => {
                          //console.log(data);
                          console.log(vis);
                          if(exportType in { pdf: true, image: true} && vis)
                            await vis.setOptions({ showTitles: true, footnote: `${ExportChartFootnote}` });
                        }}
                        onAfterExportHandler={async ({ vis, exportType }) => {
                          console.log('Export type: ', exportType);
                          if(exportType in { pdf: true, image: true})
                            vis.setOptions({ showTitles: false, footnote:"" });
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>

                </div>

              </div>

              <div className="row justify-content-left">
                {/* Table Row */}

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  {/* straight table */}
                  <div className="row justify-content-center">
                    <div className="col-12">
                      {/* table */}
                      <QlikCard
                        id="5ad5ab40-707a-44c5-8b8b-37099bad8437"
                        height="max(calc(100vh - 365px)/2, 380px)"
                        showHeader={true}
                        showMaximize={true}
                        showExport={true}
                        onBeforeExportHandler={async ({  }) => {
                          //console.log(data);
                            await app.variable.setStringValue('export_link', '1');
                        }}
                        onAfterExportHandler={async ({  }) => {
                            app.variable.setStringValue('export_link', '0');
                        }}
                        exportText = {menuItemsStrings.exportText}
                        exportCompletedText = {menuItemsStrings.exportCompletedText}
                        closeBtnText = {menuItemsStrings.closeBtnText}
                      />
                    </div>
                  </div>

                </div>

              </div>

            </div>


            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  {/* FilterPane */}
                  <QlikCard
                    id="b8a7c62f-9a4e-4f70-ae87-86556f96ea5d"
                    height="min(calc(78vh - 263px + 90px + 8px + 18.8px + 4px), calc(320px + 38px + 90px + 8px + 18.8px + 4px))"
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />
                  <div className="row justify-content-start">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <QlikObject
                        id="VgkLbR"
                        // height="485px"
                        height="min(calc(10.5vh + 2px), 64px)"
                        showHeader={false}
                        showMaximize={false}
                        showExport={false}
                        showOverlayer={false}
                      />
                    </div>
                  </div>
                  {/* Columns Selector */}
                  <QlikCard
                    id="e254b0ca-7275-4734-8806-0b288bcdb6a4"
                    height="max(calc(100vh - 365px)/2 - min(calc(10.5vh + 2px), 64px) - 18.8px - 2px, 380px - min(calc(10.5vh + 2px), 64px) - 18.8px - 2px)"
                    showHeader={false}
                    showMaximize={false}
                    showExport={false}
                    showOverlayer={false}
                  />

            </div>

          </div>

          {/* <div className="row mb-2">
            <div className="col-md-2 col-sm-3 col-2">
              <QlikObject
                id="b4231dd2-2ce3-4d63-b394-838d3c6f0b0e"
                height="0px"
                showLoader={false}
              />
            </div>
          </div> */}
          
        </div>
    </section>
  );
}
